import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class RestService<T> {
  private TOKEN_NAME: string = environment.tokenName;

  constructor(private resourceEndpoint: string, private _http: HttpClient) { }

  getAccessToken(): string {
    let token = '';
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken) {
      token = '?access_token=' + accessToken.id;
    }
    return token;
  }

  // To give access to the http instance to the class extending the service
  get http(): HttpClient {
    return this._http;
  }

  get(resource: any | string): Observable<T> {
    //console.log("get get get");
    const resourceId = resource.id || resource;
    return this._http
      .get(this.resourceEndpoint + '/' + resourceId + this.getAccessToken())
      .pipe(map((res: any) => <T>res.json()));
  }

  getAll(): Observable<T[]> {
    return this._http
      .get(this.resourceEndpoint + this.getAccessToken())
      .pipe(map((res: any) => <T[]>res.json()));
  }

  /*create(resource: T): Observable<T> {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    return this._http
      .post(this.resourceEndpoint + this.getAccessToken(), resource, options)
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || 'Server error');
        })
      );
  }*/

  create(resource: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this._http.post(
      this.resourceEndpoint + this.getAccessToken(),
      resource,
      httpOptions
    );
  }

  /*update(resource: any): Observable<T> {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const body = { ...resource }; // clone to delete id on body before sending, but no id from original project
    delete body.id;
    return this._http
      .put(
        `${this.resourceEndpoint}/${resource.id}` + this.getAccessToken(),
        body,
        options
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || 'Server error');
        })
      );
  }*/

  update(resource: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    let body = { ...resource }; //clone to delete id on body before sending, but no id from original project
    delete body.id;
    return this._http.put(
      `${this.resourceEndpoint}/${resource.id}` + this.getAccessToken(),
      body,
      httpOptions
    );
  }

  /*delete(resource: any): Observable<T> {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    return this._http
      .delete(
        `${this.resourceEndpoint}/${resource.id}` + this.getAccessToken(),
        options
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || 'Server error');
        })
      );
  }*/

  delete(resource: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this._http.delete(
      `${this.resourceEndpoint}/${resource.id}` + this.getAccessToken(),
      httpOptions
    );
  }
}
