import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SpinnerService } from '../services/spinner.service';
import { MessageService } from 'primeng/api';
//import { MessageService } from '../services/message.service';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { ExerciseService } from '../services/exercise.service';
import { ClassroomService } from '../services/classroom.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { SocketioService } from '../services/socketio.service';

import { Student } from '../models/Student';

import * as moment from "moment";



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private START_ROUTE = 'main';
  private MAX_TIME: number = 120;
  idMoodle: string;
  name: string;
  username: string;
  lang: string;
  sub: any;
  address: string;
  student: any;
  exerciseId: string;
  selectMode: boolean = false;
  isColaborative: boolean = false;
  nclassrooms: number = 0;
  nusers: number = 0;
  classrooms: any[] = [];
  users: any[] = [];
  selectedClassroom: any = null;
  selectedUser: any = null;

  constructor(private route: ActivatedRoute,
    public authService: AuthService,
    //private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private userService: UserService,
    private exerciseService: ExerciseService,
    private classroomService: ClassroomService,
    private translate: TranslateService,
    private router: Router,
    private socketioService: SocketioService
  ) {
    this.student = new Student;
    this.route.params.subscribe(params => {
      this.idMoodle = params['idMoodle'];
      this.name = params['name'];
      this.username = params['username'];
      this.lang = params['lang'];
      this.exerciseId = params['exerciseId'];

      this.login(this.idMoodle, this.name, this.username, this.exerciseId);
      this.translate.use(this.lang);
    })

  }

  ngOnInit() {
    //this.socketioService.setupSocketConnection();

  }

  login(idMoodle: string, name: string, username: string, exerciseId): void {
    this.authService.login(idMoodle, name, username).subscribe(
      student => {
        if (student.id) {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('Wellcome'),
            detail: name
          });
          this.student =  student;
          //check if exercise is colaborative
          this.exerciseService.getByExerciseId(this.exerciseId).subscribe(
            exercise => {
              let myExercise = exercise[0];
              if (myExercise !== undefined) {
                if (myExercise.type === "C") {
                  this.isColaborative = true;
                  this.nclassrooms = myExercise.classrooms;
                  this.nusers = myExercise.users;

                  //getClassrooms
                  this.classroomService.getAllClassroomFromExercise(exerciseId).subscribe(
                    rooms => {
                      rooms.forEach((room, i) => {
                        //create  options
                          let full = false;
                          if(room.users){
                            //room.users = [];
                            //this.classroomService.editClassroom(room);
                            full = (room.users.length >= room.numUsers) ? true: false;
                            //si el usuario ya está conectado puede elegir esa sala (full = false)
                            //TODO: control conexions
                            room.users.forEach((user, i) => {
                              full = (user.id === this.student.id) ? false : full;
                            });
                          }
                          let v = {
                            name: room.name,
                            key: room.id,
                            isFull: full
                          }
                          this.classrooms.push(v);
                      });
                    }
                  );
                }
                this.selectMode = true;
              }
              else {
                this.messageService.add({
                  severity: 'error',
                  summary: this.translate.instant('Error'),
                  detail: this.translate.instant('exerciseNoExist')
                });
              }
            }
          );


        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Check credential',
            detail: 'Check your moodleId'
          });
        }
        this.spinnerService.displayLoader(false);
      },
      error => {
        //first login of this teacher -> create the user
        console.log("Error: ", error);
        this.spinnerService.displayLoader(false);
      }
    );
  }

  webXR(xr: boolean) {
    this.spinnerService.displayLoader(true);
    this.selectMode = false;
    if (this.isColaborative && !this.selectedClassroom) {
      let message = this.translate.instant('SELECT_CLASSROOM');
      alert(message);
      location.reload();
    }
    else {
      if(this.isColaborative){
        this.classroomService.getClassroomById(this.selectedClassroom.key).subscribe(
          room => {
            let myRoom = Object.assign({}, room[0]);
            let myStudent = this.student;
            let currentMoment: any = moment();
            if(myRoom){
              if(myRoom.startTime === null || myRoom.startTime === undefined){
                myRoom.users = [];
                let newStartTime = moment();
                myRoom.startTime = newStartTime.toDate();
                myRoom.currentStep = 0;
              }
              let sT: any = moment(myRoom.startTime);
              let min = currentMoment.diff(sT, "minutes");
              if(min > this.MAX_TIME){
                //reset practica
                myRoom.users = [];
                let newStartTime = moment();
                myRoom.startTime = newStartTime.toDate();
                myRoom.currentStep = 0;
              }

              if(myRoom.users === null || myRoom.users === undefined){
                myRoom.users = [];
              }
              if(myRoom.users.length === 0){
                let newStartTime = moment();
                myRoom.startTime = newStartTime.toDate();
                myRoom.currentStep = 0;
              }
              let isUser = false;
              myRoom.users.forEach(user => {
                isUser = (user.id === this.student.id) ? true : isUser;
              });
              if(!isUser){
                myRoom.users.push(this.student);
                this.classroomService.editClassroom(myRoom);
              }

              if(!xr){
                this.START_ROUTE = 'exercise/:exerciseId'
                this.router.navigate(["exercise", this.exerciseId], {state: {data: {myRoom, myStudent}}});
              }
              else{
                this.START_ROUTE = 'exercisexr/:exerciseId'
                this.router.navigate(["exercisexr", this.exerciseId], {state: {data: {myRoom, myStudent}}});
              }
            }
            else{
              location.reload();
              this.messageService.add({
                severity: 'error',
                summary: 'Error with the room',
                detail: 'Some error entering the room'
              });
            }
          }
        );
      }
      else{
        let myStudent = this.student;
        let myRoom = null;
        if(!xr){
          this.START_ROUTE = 'exercise/:exerciseId'
          this.router.navigate(["exercise", this.exerciseId], {state: {data: {myRoom, myStudent}}});
        }
        else{
          this.START_ROUTE = 'exercisexr/:exerciseId'
          this.router.navigate(["exercisexr", this.exerciseId], {state: {data: {myRoom, myStudent}}});
        }

      }
    }
  }

  isClassroomFull(room: any)
  {
    this.classroomService.getClassroomById(room.id).subscribe(
      r => {
        let myRoom = Object.assign({}, r[0]);
        if(myRoom.users === null || myRoom.users === undefined)  {
          return false;
        }
        else{
          let isFull = (myRoom.users.length >= myRoom.numUsers) ? true : false;
          return isFull;
        }
      });


  }

}
