import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

import { Step } from "../models/Step";
import { Teacher } from "../models/Teacher";
import { Exercise } from "../models/Exercise";
import { MenuItem } from 'primeng/api';
import { AuthService } from '../services/auth.service';
import { StepService } from '../services/step.service';
import { ExerciseService } from '../services/exercise.service';
import { LanaService } from '../services/lana.service';
import { ClassroomService } from '../services/classroom.service';
import { MessageService } from 'primeng/api';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

import { ConfirmationService } from "primeng/api";
import { environment } from '../../environments/environment';

import { SocketioService } from '../services/socketio.service'
//import { Message } from 'primeng/api';


@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.css']
})
export class ExerciseComponent implements OnInit {

  //@Input() exercise: Exercise;
  private START_ROUTE = 'main';

  exercise: Exercise;
  isColaborative: boolean = false;
  teacher: Teacher;
  nSteps: any;
  mySteps: any;
  exerciseId: string;
  myStep: any;
  stepsMenu: MenuItem[];
  title: string = "";
  description: string = "";

  activeIndex: number = 0;
  step: Step = new Step();
  changeStep: boolean = false;
  vrMode: boolean = false;
  newStepPosition: any;

  myRoom: any;
  me: any;
  nUsers: any;
  users:any;
  state: any;
  disableControl: boolean = false;

  constructor(private authService: AuthService,
    private stepService: StepService,
    private exerciseService: ExerciseService,
    private router: Router,
    private lanaService: LanaService,
    private classroomService: ClassroomService,
    private socketioService: SocketioService,
    private route: ActivatedRoute, private messageService: MessageService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService) {

    this.route.params.subscribe(params => {
      this.exerciseId = params['exerciseId'];
      let newStep: any = [];
      newStep.title = "Insert title";
      newStep.description = "Insert description";
      newStep.help = "Insert help";
      newStep.initialModels = [];
      newStep.correctModels = [];
      newStep.incorrectModels = [];
      newStep.order = 0;
      newStep.exerciseId = "this.exerciseId";

      this.myStep = newStep;

    })

    if(history.state.data.myRoom !== null){
      this.myRoom = history.state.data.myRoom;
      this.nUsers = this.myRoom.users.length;
      this.users = this.myRoom.users;
      this.activeIndex = this.myRoom.currentStep;

      //audio
      const constraints = {
        'video': false,
        'audio': true
      }

      navigator.mediaDevices.getUserMedia(constraints)
          .then(stream => {
              this.socketioService.setupSocketConnection(this.me, this.myRoom, stream);
          })
          .catch(error => {
              console.error('Error accessing media devices.', error);
              alert("No podrá comunicarse por audio con sus compañeros.");
          });

      //this.socketioService.setupSocketConnection(this.me, this.myRoom);
    }
    this.me = history.state.data.myStudent;

  }

  ngOnInit() {
    // socketio connection
    if(history.state.data.myRoom !== null){
      this.socketioService.onAddUser().subscribe((params:any) => {
        if(this.myRoom.id === params.room.id){
          let isaddedUser = false
          for (let i = 0; i<this.users.length; i++){
            if(this.users[i].id === params.user.id){
              isaddedUser = true;
            }
          }
          if(!isaddedUser){
            this.users.push(params.user);
          }
        }
      });

      this.socketioService.onRemoveUser().subscribe((params:any) => {
        if(this.myRoom.id === params.room.id){
          //this.users.push(params.user);
          for (let i = 0; i<this.users.length; i++){
            if(this.users[i].id === params.user.id){
              this.users.splice(i, 1);
            }
          }
        }
      });

      this.socketioService.onNextStep().subscribe((params:any) => {
        if(this.myRoom.id === params.room.id){
          this.activeIndex = params.room.currentStep;
          this.myStep = this.mySteps[this.activeIndex];
          let activeUsers = this.myStep.activeUsers;
          for (let i = 0; i<activeUsers; i++){
            let user = this.myRoom.users[0];
            this.myRoom.users.splice(0, 1);
            this.myRoom.users.push(user);
          }
          this.ngOnInit();
        }
      });

      this.socketioService.onPrevStep().subscribe((params:any) => {
        if(this.myRoom.id === params.room.id){
          this.activeIndex = params.room.currentStep;
          this.removeCorrectModels();
          this.removeIncorrectModels();
          this.myStep = this.mySteps[this.activeIndex];
          let activeUsers = this.myStep.activeUsers;
          for (let i = 0; i<activeUsers; i++){
            let user = this.myRoom.users.pop();
            this.myRoom.users.unshift(user);
          }
          this.ngOnInit();
          //this.goToNext();
        }
      });

      this.socketioService.onOffer().subscribe((params:any) => {
        if(this.myRoom.id === params.room.id){
        }
      });

      this.socketioService.onCandidate().subscribe((params:any) => {
      });

      this.socketioService.onAnswer().subscribe((params:any) => {
      });
    }



    //do not reset scene
    //this.lanaService.resetScene(function (p) { }, this);
    this.lanaService.prepareScene(function (p) { }, this);
    this.myStep = undefined;
    this.authService.getLogged().subscribe(
      teacher => {
        this.teacher = teacher;
      }
    );
    this.exerciseService.getByExerciseId(this.exerciseId).subscribe(
      exercise => {
        this.exercise = exercise[0];
        this.isColaborative = (this.exercise.type === "C") ? true : false;
        this.title = exercise[0].title;
        this.description = exercise[0].description;
        this.getSteps(this.exerciseId);
      }
    );

    if(history.state.data.myRoom !==null){
      this.myRoom = history.state.data.myRoom;
      this.nUsers = this.myRoom.users.length;
    }

  }


  getSteps(exerciseId) {
    //search projects where teacher.id is editor
    this.mySteps = [];
    let myOptions: any[] = [];
    this.stepService.getAllFromExercise(exerciseId).subscribe(
      steps => {
        this.mySteps = steps;
        this.mySteps.sort(function (a, b) { return (a.order - b.order) });
        this.nSteps = this.mySteps.length;

        if (this.nSteps === 0) {
          let newStep: any = [];
          newStep.title = "Insert title";
          newStep.description = "Insert description";
          newStep.help = "Insert help";
          newStep.initialModels = [];
          newStep.correctModels = [];
          newStep.incorrectModels = [];
          newStep.helpFiles = [];
          newStep.order = 0;
          newStep.exerciseId = this.exerciseId;


          this.stepService.newStep(newStep).subscribe(step => {
            this.mySteps.push(step);
            //this.ngOnInit();

          })
        }

        for (var i = 0; i < this.mySteps.length; i++) {
          myOptions[i] = {
            command: (event: any) => {
              this.activeIndex = event.index;
              this.myStep = this.mySteps[event.index];
              this.ngOnInit();
            }
          };
        }


        this.stepsMenu = myOptions;
        this.myStep = this.mySteps[this.activeIndex];

      });
  }

  showCreateStepPositionOption() {
    this.newStepPosition = this.nSteps + 1;
    this.changeStep = true;
  }

  cancelNewStep() {
    this.changeStep = false;
  }

  onDisableControl(hasControl){
    this.disableControl = !hasControl;
  }

  goToBack() {

    //delete models
    this.removeCorrectModels();
    this.removeIncorrectModels();
    this.activeIndex = this.activeIndex - 1;
    this.myStep = this.mySteps[this.activeIndex];


    if (this.isColaborative){
      //set user control
      this.myRoom.currentStep = this.activeIndex;
      let activeUsers = this.myStep.activeUsers;
      for (let i = 0; i<activeUsers; i++){
        let user = this.myRoom.users.pop();
        this.myRoom.users.unshift(user);
      }
      this.classroomService.editClassroom(this.myRoom);

      //change other users step
      this.socketioService.prevStep();
    }
    this.ngOnInit();
  }

  goToNext() {
    this.activeIndex = this.activeIndex + 1;
    this.myStep = this.mySteps[this.activeIndex];


    if(this.isColaborative){
      //set user control.
      this.myRoom.currentStep = this.activeIndex;
      let activeUsers = this.myStep.activeUsers;
      for (let i = 0; i<activeUsers; i++){
        let user = this.myRoom.users[0];
        this.myRoom.users.splice(0, 1);
        this.myRoom.users.push(user);
      }
      this.classroomService.editClassroom(this.myRoom);

      //change other users step
      this.socketioService.nextStep();
    }
    this.ngOnInit();

  }

  removeCorrectModels(){
    if (this.myStep.correctModels.length > 0) {
      for (let model of this.myStep.correctModels) {
        let mydaeObj = model.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        console.log("deleting correct model... ", mydaeObj);
        this.lanaService.deleteModel(mydaeObj);
      }
    }
  }

  removeIncorrectModels(){
    if (this.myStep.incorrectModels.length > 0) {
      for (let model of this.myStep.incorrectModels) {
        let mydaeObj = model.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        console.log("deleting incorrect model... ", mydaeObj);
        this.lanaService.deleteModel(mydaeObj);
      }
    }
  }

  confirmExit() {
    this.confirmationService.confirm({
      message: 'Seguro que quieres salir del ejercicio?',
      accept: () => {
        this.exit();
      }
    });
  }

  exit(){
    //TODO: exit from room
    if(history.state.data.myRoom !== null){
      this.myRoom.users.forEach((user, i) => {
        if(this.me.id === user.id){
          this.myRoom.users.splice(i, 1);
          this.classroomService.editClassroom(this.myRoom);
          this.socketioService.disconnect(user, this.myRoom);
        }
      });
    }
    this.START_ROUTE = 'exerciseList';
    this.router.navigate([this.START_ROUTE]);
    this.router.navigate(['finish']);
  }

  checkFirstStep() {
    return (this.activeIndex === 0);
  }

  checkLastStep() {
    return (this.activeIndex === this.nSteps - 1);
  }

  checkVRAvailable() {
    //TODO: check VR isAvailable
    return true;
  }

  VRMode() {
    if (this.vrMode) {
      let vr = this.lanaService.setVRMode();
      if (vr) {
        let summary = this.translate.instant('VR_ERROR');
        let detail = this.translate.instant('Check_VR_device');
        this.messageService.add({ severity: 'error', summary: summary, detail: detail });
      }
    }

  }


}
