import { ExerciseComponent } from './exercise/exercise.component';
import { ExerciseListComponent } from './exercise-list/exercise-list.component';
import { ExerciseXRComponent } from './exercise-xr/exercise-xr.component';
import { LoginComponent } from './login/login.component';
import { FinishComponent } from './finish/finish.component';

import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login/:idMoodle/:name/:username/:lang/:exerciseId', component: LoginComponent },
  { path: 'exerciseList', component: ExerciseListComponent },
  { path: 'exercise/:exerciseId', component: ExerciseComponent },
  { path: 'exercisexr/:exerciseId', component: ExerciseXRComponent },
  { path: 'finish', component: FinishComponent }
];
