import { ThisReceiver } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { LanaService } from './lana.service';


@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  private socket: Socket;
  private localStream;
  private localConnection = [];
  myUser;
  myRoom;

  janodeSession;
  janodeManagerHandle;

  janodeConfig = {
    address: [{
      url: environment.apiEndpoint,
      apisecret: 'secret'
    }],
    // seconds between retries after a connection setup error
    retry_time_secs: 10
  };

  iceServers = {
    'iceServers': [
        {
            'urls': 'stun:stun.services.mozilla.com'
        },
        {
            'urls': 'stun:stun.l.google.com:19302'
        }
    ]
  };

  constructor(private lanaService: LanaService) {
    this.socket = io(environment.apiEndpoint, {transports: ['websocket']});
  }

  setupSocketConnection(user, room, stream){
    this.localStream = stream;
    this.myRoom = room;
    this.myUser = user;
    this.socket.emit("addUser", {user: user, room:room});

    this.socket.on('connect_error', ()=>{
      console.log('** Error socket connection **');
      this.socket.connect();
    });
  }

  rtcConnection(stream, audiosContainer){
        this.localStream = stream;
        /*this.localConnection[this.myUser.id] = new RTCPeerConnection(this.iceServers);
        this.localConnection[this.myUser.id].onicecandidate = (event) => {
          this.onIceCandidate(event, this.socket);
        };
        this.localConnection[this.myUser.id].ontrack = (event) => {
          this.onAddStream(event);
        };
        this.localConnection[this.myUser.id].addTrack(this.localStream.getTracks()[0], this.localStream);
        this.localConnection[this.myUser.id].createOffer()
            .then(sessionDescription =>
            {
                this.localConnection[this.myUser.id].setLocalDescription(sessionDescription);
                this.socket.emit("offer", {
                  type: 'offer',
                  sdp: sessionDescription,
                  room:this.myRoom,
                  userId:this.myUser.id
                });
            })
            .catch(error =>
            {
                console.log(error);
            });*/
  }

  onIceCandidate(event, socket){
    if (event.candidate)
    {
        if(socket){
          socket.emit('candidate', {
            type: 'candidate',
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate,
            room: this.myRoom,
            userId: this.myUser.id
        });
        }
    }
}

onAddStream(event)
{
  let sound      = document.createElement('audio') as HTMLAudioElement;
  //sound.id       = 'audio-player';
  //sound.controls = false;
  sound.srcObject      = event.streams[0];
  //sound.type     = 'audio/mpeg';
  document.getElementById('audios-container').appendChild(sound);
  setTimeout(function() {
      sound.play();
  }, 5000);
}

  // HANDLER example
  onAddUser() {
    return new Observable(observer => {
      this.socket.on('addUser', params => {
        /* create RTC Connetion */
        if(!this.localConnection[params.user.id]){
          this.localConnection[params.user.id] = new RTCPeerConnection(this.iceServers);
          this.localConnection[params.user.id].onicecandidate = (event) => {
          this.onIceCandidate(event, this.socket);
        };
        this.localConnection[params.user.id].ontrack = (event) => {
          this.onAddStream(event);
        };
        this.localConnection[params.user.id].addTrack(this.localStream.getTracks()[0], this.localStream);
        this.localConnection[params.user.id].createOffer()
            .then(sessionDescription =>
            {
                this.localConnection[params.user.id].setLocalDescription(sessionDescription);
                this.socket.emit("offer", {
                  type: 'offer',
                  sdp: sessionDescription,
                  room:this.myRoom,
                  userId:this.myUser.id
                });
            })
            .catch(error =>
            {
                console.log(error);
            });
        }

        observer.next(params);
      });
    });
  }

  onRemoveUser() {
    return new Observable(observer => {
      this.socket.on('disconnectUser', params => {
        if(this.localConnection[params.user.id]){
        this.localConnection[params.user.id].close();
        this.localConnection[params.user.id] = null;
        }
        observer.next(params);
      });
    });
  }

  onOffer() {
    return new Observable(observer => {
      this.socket.on('offer', params => {
        if(!this.localConnection[params.userId]){
          this.localConnection[params.userId] = new RTCPeerConnection(this.iceServers);
          this.localConnection[params.userId].setRemoteDescription(new RTCSessionDescription(params.sdp));
          this.localConnection[params.userId].ontrack = (event) => {
          this.onAddStream(event);
        };
        this.localConnection[params.userId].addTrack(this.localStream.getTracks()[0], this.localStream);
        this.localConnection[params.userId].createAnswer()
            .then(answer => {
              this.localConnection[params.userId].setLocalDescription(answer);
              this.socket.emit("answer", {
                type: 'answer',
                answer: answer,
                room:this.myRoom,
                userId:this.myUser.id
              });
            })
            .catch(error => console.log(`createAnswer failed: ${error}`));
        }


        observer.next(params);

      });
    });
  }

  onAnswer() {
    return new Observable(observer => {
      this.socket.on('answer', params => {
        if(this.localConnection[params.userId] && this.localConnection[params.userId].connectionState !== "connected" ){
          this.localConnection[params.userId].setRemoteDescription(new RTCSessionDescription(params.answer))
          .catch(error => console.log(`Answer failed: ${error}`));
        }

      });
    });
  }

  onCandidate() {
    return new Observable(observer => {
      this.socket.on('candidate', params => {
       let candidate = new RTCIceCandidate({
          sdpMLineIndex: params.label,
          candidate: params.candidate
      });
      this.localConnection[params.userId].addIceCandidate(candidate);
        observer.next(params);
      });
    });
  }


  offer(){
    if(this.socket){
      this.socket.emit("offer", {room:this.myRoom});
    }
  }

  onAddModel() {
    return new Observable(observer => {
      this.socket.on('addModel', params => {
        observer.next(params);
      });
    });
  }

  addModel(model:any, matrix:any){
    if(this.socket){
      this.socket.emit("addModel", {model: model, matrix: matrix, room:this.myRoom});
    }
  }

  onRemoveModel() {
    return new Observable(observer => {
      this.socket.on('removeModel', params => {
        observer.next(params);
      });
    });
  }

  removeModel(model: any){
    if(this.socket){
      this.socket.emit("removeModel", {model: model, room:this.myRoom});
    }
  }

  onMoveObject() {
    return new Observable(observer => {
      this.socket.on('moveObject', params => {
        observer.next(params);
      });
    });
  }

  moveObject(name: string, matrixWorld: any){
    if(this.socket){
      this.socket.emit("moveObject", {name: name, matrix: matrixWorld, room:this.myRoom});
    }
  }

  onNextStep() {
    return new Observable(observer => {
      this.socket.on('nextStep', params => {
        observer.next(params);
      });
    });
  }

  nextStep(){
    if(this.socket){
      this.socket.emit("nextStep", {room:this.myRoom});
    }
  }

  prevStep(){
    if(this.socket){
      this.socket.emit("prevStep", {room:this.myRoom});
    }
  }

  onPrevStep() {
    return new Observable(observer => {
      this.socket.on('prevStep', params => {
        observer.next(params);
      });
    });
  }


  disconnect(user, room){
    if(this.socket){
      this.socket.emit("disconnectUser", {user: user, room: room});
    }
  }
}
