import { Directive, HostListener, ElementRef } from '@angular/core';
import { screenfull } from 'screenfull';

@Directive({
  selector: '[appFullscreen]'
})
export class FullscreenDirective {
  myScreenfull: screenfull;

  @HostListener('click') onClick() {
    this.setFullScreen(true);
  }

  constructor(private el: ElementRef) {
    //el.nativeElement.screenfull.request();
  }

  private setFullScreen(mode: boolean) {
    if (mode) {
      //this.myScreenfull.request();
    }

  }

}
