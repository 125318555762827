import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../environments/environment";
import { Classroom } from "../models/Classroom";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";

import * as moment from "moment";

@Injectable()
export class ClassroomService {
  private BASE_URL: string = environment.apiEndpoint + '/api/classrooms/';
  private classroom: BehaviorSubject<Classroom> = new BehaviorSubject<Classroom>(null);
  private classroom$: Observable<Classroom> = this.classroom.asObservable();

  constructor(private http: HttpClient) {
  }

  newClassroom(resource: any) {
    return this.http
      .post(
        this.BASE_URL + 'newClassroom',
        {
          name: resource.name,
          numUsers: resource.numUsers,
          exerciseId: resource.exerciseId,
        }
      ).toPromise()
      .then(res => {
        return res;
      })
      .then(data => {
        return data;
      });
  }

  getAllClassroomFromExercise(exerciseId: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "exerciseId" + '\u0022' + ":" + '\u0022' + exerciseId + '\u0022' + "}}";
    //let query = "?filter={" + '\u0022' + "exerciseId" + '\u0022' + ":" + '\u0022' + exerciseId + '\u0022' + "}";
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: any) => {
          //return res.json();
          return res;
        }),
        catchError((error: any) => {
          console.log(error);
          return Observable.throw(error || "Server error");
        })
      );
  }

  getClassroomById(classroomId: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "id" + '\u0022' + ":" + '\u0022' + classroomId + '\u0022' + "}}";
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          console.log(error);
          return Observable.throw(error || "Server error");
        })
      );
  }

  editClassroom(resource: any) {
    let modified = moment();
    console.log("editClassroom", resource);
    return this.http
      .put(
        this.BASE_URL,
        {
          id: resource.id,
          exerciseId: resource.exerciseId,
          name: resource.name,
          numUsers: resource.numUsers,
          users: resource.users,
          currentStep: resource.currentStep,
          stepState: resource.stepState,
          startTime: resource.startTime,
          finishTime: resource.finishTime,
        }
      ).toPromise()
      .then(res => {
        res;
      })
      .then(data => {
        return data;
      });
  }

}
