import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { StepComponent } from '../step/step.component';
import { Observable } from 'rxjs';
import $ from "jquery";
import { MessageService } from 'primeng/api';

import { LanaService } from '../services/lana.service';
import { SocketioService } from '../services/socketio.service';

import { TranslateService } from "@ngx-translate/core";
import { disableDebugTools } from '@angular/platform-browser';

declare var LANA3D: any;
declare var VIEWER: any;

@Component({
  selector: 'app-lana',
  templateUrl: './lana.component.html',
  styleUrls: ['./lana.component.css'],
  //encapsulation: ViewEncapsulation.Native
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LanaComponent implements OnInit {

  @Input() vrMode: any;
  @Input() disabled: any;
  @Output() public playAction = new EventEmitter<string>();
  @Output() public goToNextStep = new EventEmitter<void>();
  @Output() public goToBackStep = new EventEmitter<void>();

  editor: any;
  scene: any;
  mesh: any;
  //@Input() stepComponent: StepComponent;

  myModel$: Observable<string>;
  model: string = "";

  //visor: any;

  moveChecked: boolean = true;
  rotateChecked: boolean = false;
  selectedObject: boolean = false;
  myObject: any;
  object: any;
  prevX: any = 0;
  prevY: any = 0;


  @ViewChild('lanaContainer', { static: true }) lanaContainer: ElementRef;
  @ViewChild('visor') visor: ElementRef;
  @ViewChild('viewport') viewport: ElementRef;


  constructor(private lanaService: LanaService, private messageService: MessageService, private translate: TranslateService, private socketioService: SocketioService,) {
  }

  ngOnInit() {

    this.lanaService.getEditor().subscribe(editor => {
      if (editor) {
        this.editor = editor;
        this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
        if (this.vrMode) {
          $("body").css("height", "100%");
          $("body").css("width", "100%");
          this.editor.getDomElement().style.height = '100%';
          this.editor.getDomElement().style.width = '100%';
        }
        this.editor.resize();
        this.editor.disableControl(this.disabled);
      }
    });
  }

  ngOnChanges(changes) {

    if(this.editor){
      this.editor.disableControl(this.disabled);
    }

  }

  ngAfterViewInit() {
    const self = this;
    $("#viewport").dblclick(function (event) {
      event.preventDefault();
    });

    this.editor.getDomElement().addEventListener('nextButton', function(event){
      self.goToNextStep.emit();
    });

    this.editor.getDomElement().addEventListener('backButton', function(event){
      self.goToBackStep.emit();
    });

    this.editor.getDomElement().addEventListener('moveObject', function(event){
      self.selectedObject = true;
      let obj =self.editor.getSelectedObject();
      let name = obj.name;
      let matrixWorld = obj.matrixWorld;
       self.socketioService.moveObject(name, matrixWorld);
    });
  }

  VRMode() {
    //this.visor.nativeElement.requestFullscreen();
    if (this.vrMode) {
      let vr = this.lanaService.setVRMode();
      if (!vr) {
        let summary = this.translate.instant('VR_ERROR');
        let detail = this.translate.instant('Check_VR_device');
        this.messageService.add({ severity: 'error', summary: summary, detail: detail });
      }
    }

  }

  dblclickLana(event){
     if(event.button === 0){
      if (!this.vrMode && !this.disabled) {
        this.object = this.editor.getObjectToMove(event.clientX, event.clientY);
        if (this.object !== undefined && this.object !== null) {
          this.myObject = this.object;
          this.selectedObject = true;
        }
        else {
          this.selectedObject = false;
          return;
        }
      }
   }
  }
  clickLana(event) {
    if(event.button === 0){
      if (!this.vrMode && !this.disabled) {
        let object = this.editor.getObject(event.clientX, event.clientY);
        if (object!== null){
          this.playAction.emit(object.name);
        }
      }
   }
   else{
    this.editor.unselectObject();
    this.selectedObject = false;
    this.object = null;
    }
  }


  onGamepadEvent(event) {
    //console.log("eventFromLana", event);
  }


  unclickLana(event) {
    //this.editor.deleteHelpers();
    //this.selectedObject = false;
    //this.prevX = 0;
    //this.prevY = 0;
  }


  onMouseMove(event) {
    if (this.selectedObject) {
      let name = this.object.name;
      let matrixWorld = this.object.matrixWorld;
      this.socketioService.moveObject(name, matrixWorld);
    }
  }

  onWheel(event){
    this.editor.unselectObject();
    this.selectedObject = false;
    this.object = null;
  }

  handleChangeMove(e) {
    this.moveChecked = e.checked;
    if (this.moveChecked) {
      this.rotateChecked = false;
      this.editor.setControlMode('translate');
    }
    else {
      this.rotateChecked = true;
      this.editor.setControlMode('rotate');
    }
  }

  handleChangeRotate(e) {
    this.rotateChecked = e.checked;
    if (this.rotateChecked) {
      this.moveChecked = false;
      this.editor.setControlMode('rotate');
    }
    else {
      this.moveChecked = true;
      this.editor.setControlMode('translate');
    }
  }

}
