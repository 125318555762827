export class Teacher {
  id: string = null;
  idMoodle: string = null;
  username: string = null;
  fullName: string = null;

  static hasRole(roles: string[], rolList: string[]): boolean {
    return rolList.some(rol => roles.indexOf(rol) >= 0);
  }
}

const emptyTeacher: Teacher = new Teacher();

export { emptyTeacher };
