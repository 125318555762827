import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { StepService } from '../services/step.service';

import { ExerciseComponent } from '../exercise/exercise.component'
import { Router } from '@angular/router';

import { Step } from "../models/Step";

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
  @Input() step: any;
  @Input() exercise: any;
  @Input() vrMode: any;

  @Output() public setDisabled = new EventEmitter<boolean>();
  model: string;

  showHelp: boolean = false;
  currentTutorialPage: number;// = 2;
  tutorialNumPages: number = 0;
  pdfSrc: string;

  showText: boolean = false;
  textArea: string = "";

  constructor() { }

  ngOnInit() {
    if(history.state.data.myRoom !== null && history.state.data.myRoom !== undefined){
      this.haveControl();
    }
  }

  ngOnChanges(changes) {
    this.haveControl();
  }

  public onModel(myModel: string) {
    this.model = myModel;
  }

  update() {
    this.exercise.getSteps(this.exercise.exerciseId);
    this.exercise.activeIndex = this.exercise.activeIndex - 1;
    if (this.exercise.activeIndex < 0) {
      this.exercise.activeIndex = 0;
    }
    this.exercise.ngOnInit();
  }

  haveControl(){
    let haveControl = false;
    if(history.state.data.myRoom !== null && history.state.data.myRoom !== undefined){
      let activeUsers = this.step.activeUsers;
      let myRoomUsers = history.state.data.myRoom.users;
      let myId = history.state.data.myStudent.id;
      myRoomUsers.forEach((user, i) => {
        haveControl = (user.id === myId && i < activeUsers) ? true : haveControl;
      });
      this.setDisabled.emit(haveControl);
      return haveControl;
    }
    else{
      //no colaborative
      haveControl = true;
      this.setDisabled.emit(haveControl);
      return haveControl;
    }
  }

  onPlayAction(event) {
    this.step.interactiveElements.forEach(element => {
      if (element.name === event) {
        if (element.action !== "" && element.type === 'sound') {
          let audio = new Audio(element.action.url);
          audio.play();
        }
        if (element.action !== "" && element.type === 'file') {
          this.pdfSrc = element.action.url;
          this.showHelp = true;
        }
        if (element.action !== "" && element.type === 'text') {
          this.showText = true;
          this.textArea = element.action;
        }
      }
    });
  }

  prevTutorialPage() {
    this.currentTutorialPage = this.currentTutorialPage - 1;
  }

  nextTutorialPage() {
    this.currentTutorialPage = this.currentTutorialPage + 1;
  }

  checkFirstTutorialPage() {
    if (this.currentTutorialPage === 1) {
      return true;
    }
  }

  checkLastTutorialPage() {
    if (this.currentTutorialPage === this.tutorialNumPages) {
      return true;
    }
  }

  stateRender(event) {
    let info = event._pdfInfo;
    this.tutorialNumPages = info.numPages;
  }

  closeHelpPanel() {
    this.showText = false;
  }

}
