
export class User {
  id: string = null;
  ttl: Number = 0;
  created: Date = null;
  userId: string;
  email = '';
  name = '';
  surname = '';
  roles: string[] = [];
  companyId = '-1';
  companyName = '';
  password: string = null;
  language = 'es';

  static hasRole(roles: string[], rolList: string[]): boolean {
    return rolList.some(rol => roles.indexOf(rol) >= 0);
  }
}

const emptyUser: User = new User();

export { emptyUser };
