import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';

import { TranslateService } from "@ngx-translate/core";
import { SocketioService } from "./services/socketio.service";

// declare var LANA3D: any;



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ViWork';
  editor: any;
  scene: any;
  mesh: any;


  constructor(
    public translate: TranslateService,
    private socketService: SocketioService
  ) {
  }

  // @ViewChild('lanaContainer') lanaContainer: ElementRef;

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

    //console.log("***************** call socket service");
    //this.socketService.setupSocketConnection();

    /*this.editor = LANA3D.Editor.create();
    this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
    this.editor.resize();

    this.scene = LANA3D.NodeManager.createNode("scene");
    this.mesh = LANA3D.NodeManager.createNode("mesh");

    this.editor.setScene(this.scene);

    LANA3D.AssetManager.loadBundle("models/caja.dae", result => {
      let o = result.scene;
      o.getLocalTransform().getPosition().add(new LANA3D.Vector3(20, 0, 0));
      this.scene.addChild(o);
    });*/
  }

  ngOnDestroy(){
    //console.log("***************** call socket DISCONECT service");
    /*let user, room;
    this.socketService.disconnect(user, room);*/
  }
}
