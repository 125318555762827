import { Component, OnInit, Input } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { ExerciseService } from '../services/exercise.service';

import { ExerciseListComponent } from '../exercise-list/exercise-list.component'
import { Router } from '@angular/router';

import { Exercise } from "../models/Exercise";
import { TranslateService } from "@ngx-translate/core";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

@Component({
  selector: 'app-exercise-info',
  templateUrl: './exercise-info.component.html',
  styleUrls: ['./exercise-info.component.css']
})
export class ExerciseInfoComponent implements OnInit {
  @Input() name: string; // added name property
  @Input() exercise: Exercise;
  @Input() exerciseList: ExerciseListComponent;

  displayEditExerciseDialog: boolean = false;

  exerciseform: FormGroup;


  constructor(private confirmationService: ConfirmationService,
    private exerciseService: ExerciseService,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService) { }

  ngOnInit() {
    this.exerciseform = this.fb.group({
      'title': new FormControl(this.exercise.title, Validators.required),
      'description': new FormControl(this.exercise.description, Validators.required),
      'keyword': new FormControl(this.exercise.keyword, Validators.required),
      'isPublic': new FormControl(this.exercise.isPublic),
    });
  }

  edit() {
    //console.log('edit');
  }

  showEditExerciseDialog() {
    this.displayEditExerciseDialog = true;
  }

  onSubmit() {
    let value = this.exerciseform.value;
    let exerciseEdited = this.exercise;
    exerciseEdited.title = value.title;
    exerciseEdited.description = value.description;
    exerciseEdited.isPublic = value.isPublic;
    exerciseEdited.keyword = value.keyword;
    this.editExerciseInfo(exerciseEdited);
  }


  confirm(exerciseId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this exercise?',
      accept: () => {
        // Actual logic to perform a confirmation
        this.delete(exerciseId);
      }
    });
  }

  delete(exerciseId: string) {
    this.exerciseService.deleteExercise(exerciseId);
    //reload proyects
    this.exerciseList.loadProjects();
    this.exerciseList.ngOnInit();
  }

  editExerciseInfo(value: any) {
    this.exerciseService.editExercise(value);
    this.exerciseList.loadProjects();
    this.exerciseList.ngOnInit();
  }

  goToExercise(exerciseId: string) {
    this.router.navigate(["exercise", exerciseId]);
  }

  cancel() {
    this.displayEditExerciseDialog = false;
  }
}
